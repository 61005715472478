import useTranslation from "next-translate/useTranslation"
import { FC, useEffect } from "react"
import { useInView } from "react-intersection-observer"

import { AbTestCtaVariant } from "@app/pages/home-cta/[testVariant]"
import { AddressAutocomplete } from "@app/shared/components/AddressAutocomplete"
import { Typography } from "@app/shared/components/Typography"

import {
  homeHero,
  homeHeroContent,
  homeHeroContentSubtitle,
  homeHeroContentTitle,
  homeHeroSection,
} from "../styles.css"

import { HeroImage } from "./HeroImage"

export type HomeHeroProps = {
  abTestVariant?: AbTestCtaVariant
  updateVisibility: (visibility: boolean) => void
}

export const HomeHero: FC<HomeHeroProps> = ({ abTestVariant = "control", updateVisibility }) => {
  const { lang, t } = useTranslation("sell")

  const { inView, ref } = useInView({
    initialInView: true,
  })

  useEffect(() => {
    updateVisibility(inView)
  }, [inView, updateVisibility])

  const localeMapping: Record<string, string> = {
    en: "en",
    fr: "fr",
    it: "it",
  }
  const localeSrc = localeMapping[lang] || "it"

  return (
    <section ref={ref} className={homeHeroSection} id="hero-section">
      <div className={homeHero}>
        <div className={homeHeroContent}>
          <Typography className={homeHeroContentTitle} component="h1" variant="display">
            {t("sell:title")}
          </Typography>
          <Typography className={homeHeroContentSubtitle} component="h2" variant="h4">
            {t("sell:subtitle")}
          </Typography>
          <AddressAutocomplete abTestVariant={abTestVariant} />
        </div>
        <HeroImage
          images={{
            desktop: `/images/home/hero-${localeSrc}.webp`,
            mobile: `/images/home/hero-mobile-${localeSrc}.webp`,
          }}
        />
      </div>
    </section>
  )
}

import { Body, H2 } from "@casavo/habitat"
import { motion } from "framer-motion"
import React, { ReactNode, useRef } from "react"

import { MyImage } from "@app/shared/components/MyImage"
import { useFadeInTransition } from "@app/shared/hooks/useFadeInTransition"

import {
  benefitContent,
  benefitImage,
  benefitWrapper,
  benefitNote,
  benefitNoteIcon,
  benefitContentReverse,
  sideImageContainer,
  sideImageBadge,
  sideImageContainerReverse,
  benefitDescription,
} from "./styles.css"

type BenefitProps = {
  badgeSlot?: ReactNode
  description: string
  note: string
  reverse?: boolean
  sideImageAlt: string
  sideImageSrc: string
  title: string
}
export const Benefit: React.FC<BenefitProps> = ({
  badgeSlot,
  description,
  note,
  reverse = false,
  sideImageAlt,
  sideImageSrc,
  title,
}) => {
  const targetRef = useRef(null)
  const { opacity, y } = useFadeInTransition({ targetRef })
  return (
    <motion.div
      ref={targetRef}
      className={`${benefitContent} ${reverse ? benefitContentReverse : ""}`}
      style={{ opacity, y }}
    >
      <div className={`${sideImageContainer} ${reverse ? sideImageContainerReverse : ""}`}>
        <MyImage alt={sideImageAlt} className={benefitImage} height={377} src={sideImageSrc} width={576} />
        {badgeSlot && <div className={sideImageBadge}>{badgeSlot}</div>}
      </div>
      <div className={benefitWrapper}>
        <H2 noMargin>{title}</H2>
        <div className={benefitDescription}>
          <Body noMargin size="l">
            {description}
          </Body>
        </div>
        <div className={benefitNote}>
          <MyImage
            alt="note icon"
            className={benefitNoteIcon}
            height={44}
            src="/images/home/why-sell-with-casavo/note.svg"
            width={44}
          />
          <Body noMargin html={note} size="s" />
        </div>
      </div>
    </motion.div>
  )
}
